<template>
    <div class="sector">
        <div class="sector-head">
            <h1 class="sector-head-title">{{ titles.sector }}</h1>
        </div>

        <div class="form-field-plus">
            <div class="field-plus-legend">Clique para adicionar</div>
            <i class="fa fa-long-arrow-right arrow1" aria-hidden="true"></i>
            <div>
                <v-btn fab dark color="secondary" @click="actionCreate()">
                    <v-icon dark> mdi-account-plus </v-icon>
                </v-btn>
            </div>
        </div>

        <div class="box-table">
            <v-data-table
                :headers="headers"
                :items="reasons"
                :page.sync="pagination.page"
                :items-per-page="pagination.itemsPerPage"
                hide-default-footer
                class="data-table elevation-2"
                @page-count="pagination.pageCount = $event">
                <template v-slot:[`item.index`]="{ item }"> {{ item.index }}</template>

                <template v-slot:[`item.emotion`]="{ item }">
                    <v-img
                        :src="require(`@/assets/images/emotions/${item.emotion}`)"
                        width="35" />
                </template>

                <template v-slot:[`item.avatar`]="{}">
                    <v-avatar color="#dddddd" size="40"></v-avatar>
                </template>

                <template v-slot:[`item.active`]="{ item }">
                    <v-icon :color="getIconActivatedComputed(item.active).color">
                        {{ getIconActivatedComputed(item.active).icon }}
                    </v-icon>
                </template>

                <template v-slot:[`item.createdAt`]="{ item }">
                    {{ formatDateComputed(item.createdAt) }}
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <div v-if="action && item.id === itemIdActivated" class="confirm">
                        <div>Confirma remoção?</div>
                        <div>
                            <v-btn
                                color="secondary"
                                class="btn-action option confirm-ok"
                                x-small
                                elevation="0"
                                rounded
                                dark
                                @click="actionConfirmRemove(item.id, true)">
                                Sim
                            </v-btn>

                            <v-btn
                                color="red"
                                class="btn-action option"
                                x-small
                                elevation="0"
                                rounded
                                dark
                                @click="actionConfirmRemove(item.id, false)">
                                Não
                            </v-btn>
                        </div>
                    </div>
                    <div v-else>
                        <v-progress-circular
                            :size="20"
                            color="gray"
                            v-show="item.stateActive"
                            indeterminate>
                        </v-progress-circular>

                        <v-btn
                            v-show="!item.stateActive"
                            :title="getConfigButtonAllowedComputed(item.active).title"
                            class="btn-action"
                            x-small
                            elevation="1"
                            rounded
                            plain
                            :disabled="item.stateActive"
                            :color="getConfigButtonAllowedComputed(item.active).color"
                            @click="actionToggleStatusActive(item.id)">
                            {{ getConfigButtonAllowedComputed(item.active).title }}
                        </v-btn>

                        <v-btn @click="actionEdit(item.id)" icon title="Editar">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>

                        <v-btn icon title="Remover" @click="actionRemove(item.id)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </div>
                </template>
            </v-data-table>
            <div class="text-center pt-2">
                <v-pagination
                    v-model="pagination.page"
                    :length="pagination.pageCount"></v-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import { filterPropertiesInObject } from '@/utils/filterProperties';
    import { isAmbientGeralTests } from '@/configs/global.config';
    import reasonsMock from '@/modules/admin/mocks/reasons.admin.mock';

    export default {
        name: 'ListReason',
        data() {
            return {
                isAmbientLocalTests: true,
                itemIdActivated: null,
                action: null,
                pagination: {
                    page: 1,
                    pageCount: 0,
                    itemsPerPage: 10
                },
                titles: {
                    sector: 'Motivos'
                },
                headers: [
                    { text: '#', value: 'index' },
                    { text: 'Id', value: 'id' },
                    { text: 'Ícone', value: 'emotion', align: 'center' },
                    { text: 'Descrição', value: 'description' },
                    { text: 'Data cadastro', value: 'createdAt', align: 'center' },
                    { text: 'Ativo?', value: 'active', align: 'center' },
                    { text: 'Ações', value: 'actions', sortable: false, align: 'center' }
                ],
                reasons: [],
                emotions: []
            };
        },
        mounted() {
            this.populateGeral();
        },
        computed: {
            formatDateComputed() {
                return (date) => {
                    return moment(date).format('DD/MM/YYYY');
                };
            },
            getIconActivatedComputed() {
                return (active) => {
                    return {
                        icon: 'mdi-check-circle',
                        color: active ? 'green' : '#d0d0d0'
                    };
                };
            },
            getConfigButtonAllowedComputed() {
                return (active) => {
                    return {
                        color: active ? 'red' : 'green',
                        title: active ? 'Desativar' : 'Ativar'
                    };
                };
            }
        },
        methods: {
            isAmbientTests() {
                return isAmbientGeralTests && this.isAmbientLocalTests;
            },
            async populateGeral() {
                if (this.isAmbientTests()) {
                    this.reasons = reasonsMock.reasons;
                } else {
                    await this.serviceLoadingEmotions();

                    await this.serviceLoadingReasons();
                }
            },
            mockActionRemove(reasonId) {
                this.reasons = this.reasons.filter((g) => g.id !== reasonId);
            },
            serviceActionRemove(reasonId) {
                this.$store.commit('setGlobalLoading', true);

                this.$service.http['admin']
                    .deleteReason(reasonId)
                    .then((_) => {
                        this.serviceLoadingReasons();
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally((_) => {
                        this.$store.commit('setGlobalLoading', false);
                    });
            },
            async serviceToggleStatusActive(reasonId) {
                await this.$service.http['admin']
                    .toggleStatusActiveReason(reasonId)
                    .then((_) => null)
                    .catch((e) => {
                        console.error(e);
                    });
            },
            async actionToggleStatusActive(reasonId) {
                const reasonIndex = this.reasons.findIndex((e) => e.id === reasonId);

                if (reasonIndex !== -1) {
                    this.reasons[reasonIndex].stateActive = true;

                    await this.serviceToggleStatusActive(reasonId);

                    await new Promise((resolve) => setTimeout(resolve, 2000));

                    this.reasons[reasonIndex].active = !this.reasons[reasonIndex].active;

                    this.reasons[reasonIndex].stateActive = false;
                }
            },
            actionCreate() {
                this.$store.dispatch('setReasonEditionMode', false);
                this.$store.dispatch('setReasonId', null);
                this.$router.push({ name: 'formReasonAdmin' });
            },
            actionEdit(reasonId) {
                this.$store.dispatch('setReasonEditionMode', true);
                this.$store.dispatch('setReasonId', reasonId);
                this.$router.push({ name: 'formReasonAdmin' });
            },
            actionRemove(reasonId) {
                this.action = true;
                this.itemIdActivated = reasonId;
            },
            actionConfirmRemove(reasonId, confirm) {
                if (confirm) {
                    if (this.isAmbientTests()) {
                        this.mockActionRemove(reasonId);
                    } else {
                        this.serviceActionRemove(reasonId);
                    }
                }

                this.action = false;
                this.itemIdActivated = null;
            },
            dataFilterEmotions(emotions) {
                const propertiesToFilter = ['id', 'iconFile'];

                return emotions.map((e) =>
                    filterPropertiesInObject(e, propertiesToFilter)
                );
            },
            syncronizeDatas() {
                const findEmotion = (emotionId) =>
                    this.emotions.find((e) => e.id === emotionId);

                this.reasons.map((e) => {
                    e.emotion = findEmotion(e.emotionId)?.iconFile;
                });
            },
            async serviceLoadingEmotions() {
                this.$store.commit('setGlobalLoading', true);

                let query = '';

                await this.$service.http['admin']
                    .filterEmotions(query)
                    .then((result) => {
                        const records = result.data.docs;

                        this.emotions = this.dataFilterEmotions(records);
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally((_) => {
                        this.$store.commit('setGlobalLoading', false);
                    });
            },
            async serviceLoadingReasons() {
                this.$store.commit('setGlobalLoading', true);

                let query = '?active=true';

                await this.$service.http['admin']
                    .filterReasons(query)
                    .then((result) => {
                        const records = result.data.docs;

                        this.reasons = records;

                        this.reasons.forEach((_, i) => {
                            this.reasons[i].index = i + 1;

                            this.$set(this.reasons[i], 'stateActive', false);

                            this.$set(this.reasons[i], 'emotion', null);
                        });

                        this.syncronizeDatas();
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally((_) => {
                        this.$store.commit('setGlobalLoading', false);
                    });
            }
        }
    };
</script>

<style scoped>
    .confirm {
        padding: 5px;
    }

    .btn-action {
        letter-spacing: 0em;
    }

    .confirm-ok {
        margin-right: 10px;
    }

    .option {
        width: 30px !important;
    }

    .form-field-plus {
        display: flex;
        position: fixed;
        align-items: center;
        right: 15px;
        bottom: 60px;
        z-index: 1000;
    }

    .field-plus-legend {
        font-size: 14px;
        color: #777777;
    }

    @keyframes slide1 {
        0%,
        100% {
            transform: translate(0, 0);
        }

        50% {
            transform: translate(10px, 0);
        }
    }

    .arrow1 {
        animation: slide1 1s ease-in-out infinite;
        margin-left: 10px;
        margin-right: 20px;
    }

    .btn-action {
        text-transform: none !important;
        font-weight: bold;
    }

    .sector-head {
        margin-bottom: 15px;
    }

    .sector-head-title {
        text-align: left !important;
    }

    .v-data-table-header {
        background-color: #d0d0d0;
    }
</style>
