export default {
    reasons: [
        {
            id: '21314',
            description: 'Me apelidou',
            active: true,
            createdAt: '2022/10/09'
        },
        {
            id: '21315',
            description: 'Me bateu',
            active: true,
            createdAt: '2022/10/09'
        }
    ]
};
